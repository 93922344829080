import { CSSProperties } from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';

export interface RoleProps extends BadgeProps {
  cursor: CSSProperties['cursor']
  hasRole: boolean
}
const Role = ({ cursor, hasRole = true, ...props }: RoleProps) => (
  <Badge
    bg={hasRole ? 'primary' : 'secondary'}
    style={{ ...cursor ? { cursor } : {} }}
    className='role'
    {...props}
  />
);
export default Role;
