import { CSSProperties } from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';

export interface TeamleaderCustomFieldDefinition extends BadgeProps {
  cursor: CSSProperties['cursor'];
  hasTeamleaderCustomFieldDefinition: boolean;
}

const TeamleaderCustomFieldDefinition = ({
  cursor,
  hasTeamleaderCustomFieldDefinition = true,
  ...props
}: TeamleaderCustomFieldDefinition) => (
  <Badge
    bg={hasTeamleaderCustomFieldDefinition ? 'primary' : 'secondary'}
    style={{ ...cursor ? { cursor } : {} }}
    className='teamleader-custom-field-definition'
    {...props}
  />
)
export default TeamleaderCustomFieldDefinition
