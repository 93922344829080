import { TeamleaderDeal } from '../../redux/types';

export type TeamleaderNameProps = {
  teamleaderDeal: TeamleaderDeal;
}
const TeamleaderName = ({ teamleaderDeal: td }: TeamleaderNameProps) => (
  <a href={td.data.web_url} target='_blank' rel='noreferrer'>{td.data.title}</a>
);

export default TeamleaderName;
