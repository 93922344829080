import { ReactNode } from 'react'
import { Row, Col, Container } from 'react-bootstrap';

export type ErrorPageProps = {
  children: ReactNode;
}
const ErrorPage = ({ children }: ErrorPageProps) =>(
  <Container>
    <Row className="error-page">
      <Col>
        <div className="jumbotron">
          {children}
        </div>
      </Col>
    </Row>
  </Container>
)
export default ErrorPage
