import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { use } from '../../redux/factory';
import { getToday, usePrevious } from '../../utils/Utils';
import FrappeGantt, { Task, ViewMode} from '../production/FrappeGantt';
import { getUpdateTasksList, getTaskPopupComponent } from '../production/ProductionTemplate';
import ProductionTemplatesFormField from '../production/ProductionTemplatesFormField';
import { CreateEditModal as EditModal, SmallSpinner, FormFields } from '../shared/ReactToolbox';
import ErrorPage from '../shared/ErrorPage';
import ProjectTaskModal from './ProjectTaskModal';
import TeamleaderClient from '../teamleader/TeamleaderClient';
import NotesInput from '../shared/NotesInput';
import { ProjectTask, Project } from '../../redux/types';

// A project has already started. This also means it can be unassigned so someone
// else can start it again
export const hasStarted = (
  ({ status }: ProjectTask) =>
    status === 'started' || status === 'unassigned'
);

export const createFormFields = {
  name: {
    label: 'Naam',
  },
  description: {
    label: 'Beschrijving',
  },
  production_template: {
    label: 'Productie template',
    component: ProductionTemplatesFormField,
  },
} as FormFields;

export const updateFormFields = {
  ..._.cloneDeep(createFormFields),
  production_template: {
    label: 'Productie template',
    component: ProductionTemplatesFormField,
    formProps: { disabled: true },
  },
} as FormFields;

const ProjectView = ({ id }: Project) => {
  const {
    project,
    getProject,
    updateProject,
    startTask,
    finishTask,
  } = use.projects(id);
  const { getTaskTemplatesList, taskTemplatesList } = use.taskTemplates();
  const { productionTemplatesList } = use.productionTemplates();
  const { teamleaderDealsList, getTeamleaderDealsList } = use.teamleaderDeals();
  const [clickedTaskId, setClickedTaskId] = useState<number | null>(null);
  const [tasksList, setTasksList] = useState<ProjectTask[] | null>(null);
  const [tasksRecursiveList, setTasksRecursiveList] = useState(null);
  useEffect(() => {
    getProject(id);
    getTaskTemplatesList();
    getTeamleaderDealsList();
  }, []);
  const updateTasksList = getUpdateTasksList({
    setTasksList,
    setTasksRecursiveList,
    taskTemplatesList,
    // start: getToday().add(1, 'day'),
    // setColor: true,
  })
  useEffect(() => {
    // Tasks are only included in the api when a single project is retrieved
    if (!_.isEmpty(project) && project.tasks && !_.isEmpty(taskTemplatesList) && !tasksList) {
      updateTasksList(project.tasks);
    }
  }, [project, taskTemplatesList, tasksList]);
  const [editProjectModalActive, setEditProjectModalActive] = useState(false);

  if (!project || !project.tasks || !productionTemplatesList || !teamleaderDealsList) return <SmallSpinner className='vertical-space' />;

  if (!_.isEmpty(tasksRecursiveList)) {
    return <ErrorPage>Het project kan niet getoond worden door een recursieve afhankelijk van de taken.</ErrorPage>
  }
  const clickedTask = (
    tasksList && clickedTaskId && tasksList.find(({ id }) => id === clickedTaskId)
  );
  const TaskPopup = getTaskPopupComponent(clickedTask);

  const productionTemplate = productionTemplatesList[project.production_template];
  return (
    <div className='vertical-space'>
      <h4>
        <TeamleaderClient teamleaderDeal={project.teamleader_deal} />
        {project.teamleader_deal.data.reference
          ? <>
            &nbsp;(
              <a href={project.teamleader_deal.data.web_url} target='_blank' rel='noreferrer'>
                {project.teamleader_deal.data.reference}
              </a>
            )&nbsp;
          </>
          : ''
        }
         -
        <span
          onClick={() => setEditProjectModalActive(true)}
          style={{ cursor: 'pointer' }}
        >
          {project.name}
        </span>
      </h4>

      {project.description && <p>{project.description}</p>}

      {productionTemplate &&
        <h5>
          <Link to={`/production/${productionTemplate.id}`}>{productionTemplate.name}</Link>
        </h5>
      }

      <NotesInput
        notes={project.notes}
        updateNotes={(notes) => updateProject({ ...project, notes })}
        loading={updateProject.isLoading}
      />

      {tasksList && tasksList.length > 0
        ? <FrappeGantt
            // onTaskChange={(changedTask:, tasks) => {
            //   //setTasksList(project.tasks);
            // }}
            tasks={tasksList}
            viewMode={ViewMode.Day}
            customPopupHtml={(task: ProjectTask) => `<div id="task-${task.id}"></div>`}
            customPopupHtmlCallback={({ id }: Project) => setClickedTaskId(id)}
            readOnly={true}
          />
        : null
      }
      {clickedTask &&
        <ProjectTaskModal
          task={clickedTask}
          onHide={() => setClickedTaskId(null)}
          updateTasksList={
            (task: ProjectTask) => updateTasksList(
              task,
              // { callback: () => console.log('callback') }
              // { callback: () => setClickedTaskId(null) }
            )}
          project={project}
        />
      }
      {/* {TaskPopup && false && clickedTask &&
        <TaskPopup>
          <span>
            {clickedTask.status === 'unassigned' &&
              <PlayButton
                onClick={() => startTask(
                  clickedTask,
                  {
                    args: { project },
                    callback:
                      (project: Project) => {
                        updateTasksList(project.tasks);
                        setClickedTaskId(null);
                      },
                  }
                )}
                title='Taak starten'
                loading={startTask.isLoading}
              />
            }
            {clickedTask.status === 'started' &&
              <CheckButton
                onClick={() => finishTask(
                  clickedTask,
                  {
                    args: { project },
                    callback:
                      (project: Project) => {
                        updateTasksList(project.tasks);
                        setClickedTaskId(null);
                      },
                  }
                )}
                title='Taak afsluiten'
                loading={finishTask.isLoading}
              />
            }
          </span>
        </TaskPopup>
      } */}

      {editProjectModalActive &&
        <EditModal
          onHide={() => setEditProjectModalActive(false)}
          modalTitle='Project aanpassen'
          loading={updateProject.isLoading}
          initialState={project}
          // @ts-ignore
          formFields={updateFormFields}
          // @ts-ignore
          onSave={project => updateProject(
            project,
            { callback: () => setEditProjectModalActive(false) }
          )}
        />
      }
    </div>
  );
};
export default ProjectView
