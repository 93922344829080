import { useState, useEffect } from 'react';
import moment from 'moment';
import { Container, Tabs, Tab, Modal } from 'react-bootstrap';

import { use } from '../../redux/factory';
import CreateProjectModal from '../projects/CreateProjectModal';
import {
  SyncButton,
  CreateButton,
  CogButton,
  HideButton,
  ShowButton,
  ConfirmButton,
  CreateEditModal as EditModal,
  SmallSpinner,
  DataTable,
  UnlockButton,
} from '../shared/ReactToolbox';
import TeamleaderDealPhasesListModal from './TeamleaderDealPhasesListModal';
import TeamleaderCustomFieldDefinitionsFormField from '../teamleader/TeamleaderCustomFieldDefinitionsFormField';
import TeamleaderClient from './TeamleaderClient';
import TeamleaderName from './TeamleaderName';
import ProjectsDataTable from '../projects/ProjectsDataTable';

const TeamleaderDealsList = () => {
  const {
    synchronizeTeamleaderDeals,
    teamleaderDealsList,
    getTeamleaderDealsList,
    getTeamleaderDeal,
    hideTeamleaderDeal,
    showTeamleaderDeal,
  } = use.teamleaderDeals();
  const { getTeamleaderDealPhasesList, teamleaderDealPhasesList } = use.teamleaderDealPhases()
  const { projectsList, getProjectsList } = use.projects()
  const { updateSetting, settingsList, getSettingsList } = use.settings()
  const { teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions()
  const { getProductionTemplatesList } = use.productionTemplates()
  const { connectTeamleader } = use.teamleaderDeals();
  const [teamleaderDealIdInProjectsListInModal, setTeamleaderDealIdInProjectsListInModal] = useState(null);

  useEffect(() => {
    getTeamleaderDealsList();
    getProjectsList();
    getProductionTemplatesList();
    getTeamleaderDealPhasesList();
  }, []);
  useEffect(() => {
    if (synchronizeTeamleaderDeals.error) {
      // Get settings to find out if teamleader is no longer synchronized
      getSettingsList();
    }
  }, [!!synchronizeTeamleaderDeals.error])
  useEffect(() => {
    if (settingsList && !settingsList.teamleader_access_token_valid?.value) {
      window.scrollTo(0, 0);
    }
  }, [settingsList?.teamleader_access_token_valid?.value])
  const [teamleaderDealIdInCreateProjectModal, setTeamleaderDealIdInCreateProjectModal] = useState(null)
  const [phasesListModalActive, setPhasesListModalActive] = useState(false);
  const [customFieldDefinitionsModalActive, setCustomFieldDefinitionsModalActive] = useState(false);
  const [activeTab, setActiveTab] = useState('new');


  if (
    !teamleaderDealsList
    || !teamleaderDealPhasesList
    || !settingsList
    || !teamleaderCustomFieldDefinitionsList
    || !projectsList
  ) return <SmallSpinner className='vertical-space' />;

  const cfd = settingsList.teamleader_custom_field_definitions_in_deals_table;
  const tcfdl = teamleaderCustomFieldDefinitionsList

  return <>
    <Container className='container-list container-table'>
      <Tabs
        activeKey={activeTab}
        onSelect={tab => {
          setActiveTab(tab);
        }}
        className='mb-3'
      >
        {Object.entries({
          new: 'Nieuw',
          with_project: 'Met Project',
          hidden: 'Verborgen',
        }).map(([eventKey, title], key) => (
          <Tab {...{ eventKey, title, key }} />
        ))}
      </Tabs>

      <DataTable
        rowsPerPage={null}
        filterColumn={td => `${
          td.data.title
        } ${
          td.company ? td.company.name : ''
        } ${
          td.contact && td.contact.first_name ? td.contact.first_name : ''
        } ${
          td.contact && td.contact.last_name ? td.contact.last_name : ''
        }`}
        columns={[
          {
            orderBy: td => td.department && td.department.name,
            name: 'Bedrijfsentiteit',
            selector: td => td.department
              ? td.department.name
              : <i>Niet gevonden</i>
          },
          {
            orderBy: td => td.contact && td.contact.last_name,
            name: 'Klant',
            selector: td => <TeamleaderClient teamleaderDeal={td} />,
          },
          {
            name: 'Naam',
            orderBy: td => td.data.title,
            selector: td => <TeamleaderName teamleaderDeal={td} />
          },
          {
            name: 'Fase',
            orderBy: td => teamleaderDealPhasesList[td.phase] && teamleaderDealPhasesList[td.phase].name,
            selector: td => {
              const phase = teamleaderDealPhasesList[td.phase]
              if (!phase) {
                return <i>Niet gevonden</i>
              }
              return phase.name;
            },
          },
          ...cfd.value.reduce((o, id) => ([
            ...o,
            {
              name: tcfdl[id].label,
              orderBy: td =>
                (
                  td.custom_fields.find(cf => cf.definition === id) || {}
                ).value || '',
              selector: td => {
                const cf = td.custom_fields.find(cf => cf.definition === id)
                if (!cf || cf.value === null) return <i>Leeg</i>
                return cf.value;
              },
            }
          ]), []),
          ...activeTab !== 'new' ? [{
            name: 'Projecten',
            selector: (td) =>
              td.projects.length > 0
                ? <p
                    onClick={() => setTeamleaderDealIdInProjectsListInModal(td.id)}
                  >
                    {td.projects.join(', ')}
                  </p>
                : <i>Geen</i>,
          }] : [],
          {
            name: 'Aangemaakt op',
            orderBy: 'created_at',
            selector: ({ created_at }) => moment(created_at).format('DD-MM-YYYY HH:mm'),
          },
          {
            name: 'Acties',
            selector: td => <>
              <CreateButton
                className='float-end'
                onClick={() => setTeamleaderDealIdInCreateProjectModal(td.id)}
                style={{ marginLeft: '4px' }}
                title='Nieuw project maken van deze offerte'
              />

              {activeTab === 'hidden'
                ? <ShowButton
                    onClick={() => showTeamleaderDeal(td)}
                    loading={showTeamleaderDeal.isLoading && showTeamleaderDeal.data.id === td.id}
                    className='float-end'
                  />
                : <HideButton
                    onClick={() => hideTeamleaderDeal(td)}
                    loading={hideTeamleaderDeal.isLoading && hideTeamleaderDeal.data.id === td.id}
                    className='float-end'
                    title='Verbergen'
                  />
              }
            </>,
          },
        ]}
        data={
          Object.values(teamleaderDealsList).filter(
            ({ hidden, projects }) =>
              activeTab === 'new'
              ? !hidden && projects.length === 0
              : activeTab === 'with_project'
              ? !hidden && projects.length > 0
              : hidden
            )}
        orderByDefault='created_at'
        orderByDefaultDirection='desc'
      />

      <CogButton onClick={() => setPhasesListModalActive(true)}>
        Fases beheren
      </CogButton>
      &nbsp;
      <CogButton onClick={() => setCustomFieldDefinitionsModalActive(true)}>
        Kolommen beheren
      </CogButton>

      {settingsList.teamleader_access_token_valid?.value &&
        <ConfirmButton
          modalTitle='Nieuwe offertes ophalen'
          modalBody='Nu synchroniseren met Teamleader?'
          closeUsingCallback
          disabled={synchronizeTeamleaderDeals.isLoading}
          loading={synchronizeTeamleaderDeals.isLoading}
          onConfirm={(close) =>
            synchronizeTeamleaderDeals(true, { callback: close })
          }
          buttonComponent={SyncButton}
        >
          Nieuwe offertes ophalen
        </ConfirmButton>
      }

      <UnlockButton
        title='Inloggen bij Teamleader'
        className='float-end'
        onClick={() => connectTeamleader()}
      >
        Teamleader authorizeren
      </UnlockButton>

      {phasesListModalActive &&
        <TeamleaderDealPhasesListModal onHide={() => setPhasesListModalActive(false)}/>
      }

      {teamleaderDealIdInCreateProjectModal &&
        <CreateProjectModal
          onHide={() => setTeamleaderDealIdInCreateProjectModal(null)}
          onCreate={async () => {
            await getTeamleaderDeal(teamleaderDealIdInCreateProjectModal)
            setTeamleaderDealIdInCreateProjectModal(null)
            setTeamleaderDealIdInProjectsListInModal(teamleaderDealIdInCreateProjectModal)
            setActiveTab('with_project')
          }}

          teamleaderDeal={teamleaderDealsList[teamleaderDealIdInCreateProjectModal]}
        />
      }
      {customFieldDefinitionsModalActive &&
        <EditModal
          onHide={() => setCustomFieldDefinitionsModalActive(false)}
          modalTitle='Kolommen toevoegen'
          loading={updateSetting.isLoading}
          formFields={{
            value: {
              label: 'Offertevelden',
              component: TeamleaderCustomFieldDefinitionsFormField,
            }
          }}
          initialState={cfd}
          onSave={setting => updateSetting(
            setting,
            { callback: () => setCustomFieldDefinitionsModalActive(false) }
          )}
        />
      }
      {teamleaderDealIdInProjectsListInModal &&
        <Modal
          onHide={() => setTeamleaderDealIdInProjectsListInModal(null)}
          onClick={e => e.stopPropagation()}
          centered
          dialogClassName='mw-100 w-100'
          show
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Offerte
              <TeamleaderName
                teamleaderDeal={
                  teamleaderDealsList[teamleaderDealIdInProjectsListInModal]
                }
              />
              &nbsp;van&nbsp;
              <TeamleaderClient
                teamleaderDeal={
                  teamleaderDealsList[teamleaderDealIdInProjectsListInModal]
                }
              />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ProjectsDataTable
              projects={teamleaderDealIdInProjectsListInModal && Object.values(projectsList)
                .filter(({ id }) => teamleaderDealsList[teamleaderDealIdInProjectsListInModal]?.projects.includes(id))}
            />

              <CreateButton
                onClick={() => setTeamleaderDealIdInCreateProjectModal(teamleaderDealIdInProjectsListInModal)}
                style={{ marginLeft: '4px' }}
                title='Nieuw project maken van deze offerte'
              />
          </Modal.Body>
        </Modal>
      }
    </Container>
  </>;
};

export default TeamleaderDealsList;
