import { useNavigate } from 'react-router-dom';
import { use } from '../../redux/factory';
import { CreateEditModal as CreateModal } from '../shared/ReactToolbox';
import { createFormFields as projectFormFields } from './ProjectView';
import { Project, TeamleaderDeal } from '../../redux/types';

export type CreateProjectModalProps = {
  onHide: () => void;
  onCreate: (project: Project) => void;
  teamleaderDeal: TeamleaderDeal
}

const CreateProjectModal = ({ onHide, onCreate, teamleaderDeal }: CreateProjectModalProps) => {
  const { createProject } = use.projects();
  return (
    <CreateModal
      onHide={onHide}
      modalTitle={`Project toevoegen aan "${teamleaderDeal.data.title}"`}
      dialogClassName='mw-100 w-75'
      loading={createProject.isLoading}
      formFields={projectFormFields}
      initialState={{
        name: teamleaderDeal.data.title,
        production_template: null,
      }}
      includeData={{
        teamleader_deal: teamleaderDeal.id,
      }}
      // @ts-ignore
      onSave={(project: Project) => createProject(
        project,
        { callback: async (project: Project) => await onCreate(project)}
      )}
    />
  );
}
export default CreateProjectModal;
