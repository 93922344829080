import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { use } from '../../redux/factory';
import { SmallSpinner } from '../shared/ReactToolbox';

const TeamleaderUnauthorizedAlert = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const { settingsList } = use.settings();
  const { connectTeamleader } = use.teamleaderDeals();

  if (!settingsList || settingsList.teamleader_access_token_valid?.value) {
    return null;
  }

  return (
    <Alert
      variant="warning"
      onClick={() => {
        setShowSpinner(true);
        connectTeamleader();
      }}
      className='teamleader-unauthorized-alert'
    >
      <Alert.Heading>
        De Teamleader authorisatie is verlopen
        {showSpinner && <SmallSpinner />}
      </Alert.Heading>
      <p>
        Er is iets misgegaan tijdens het verversen van de Teamleader authorisatie token en daarom kunnen de offertes niet meer worden gesynchroniseerd. Klik hier om Teamleader opnieuw te authoriseren.
      </p>
    </Alert>
  )
}

export default TeamleaderUnauthorizedAlert;
