import { useEffect, ChangeEvent } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { use } from '../../redux/factory';
import { SmallSpinner, DataTable } from '../shared/ReactToolbox';
import { TeamleaderDealPhase } from '../../redux/types';

export type TeamleaderDealPhasesListModalProps = {
  show: boolean;
  onHide: () => void;
}
// To do: Refactor to DatatableModal component
const TeamleaderDealPhasesListModal = ({
  show = true,
  onHide
}: TeamleaderDealPhasesListModalProps) => {
  const {
    teamleaderDealPhasesList,
    updateTeamleaderDealPhase,
    getTeamleaderDealPhasesList,
  } = use.teamleaderDealPhases();
  const { getTeamleaderDealsList } = use.teamleaderDeals();
  useEffect(() => {
    getTeamleaderDealPhasesList();
  }, [])
  if (!teamleaderDealPhasesList) return null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
      centered
      dialogClassName='mw-100 w-50'
    >
      <Modal.Header closeButton>
        <Modal.Title>Fases</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DataTable
          showHeader={false}
          rowsPerPage={null}
          data={Object.values(teamleaderDealPhasesList) as TeamleaderDealPhase[]}
          columns={[
            {
              name: 'Fase',
              selector: ({ name }) => <>{name}</>,
            },
            {
              name: 'Teamleader deals',
              selector: phase =>
                <Button
                  variant={phase.hide_deals ? 'secundary' : 'primary'}
                  onClick={
                    () => updateTeamleaderDealPhase(
                      {
                        ...phase,
                        hide_deals: !phase.hide_deals,
                      },
                      {
                        callback: (
                          phase: number,
                          { getTeamleaderDealsList }: { getTeamleaderDealsList: () => void}
                        ) => getTeamleaderDealsList()
                      })
                    }
                >
                  {phase.hide_deals ? 'Niet ophalen' : 'Wel ophalen'}
                </Button>,
            },
          ]}
        />
      </Modal.Body>

      <Modal.Footer>
        {(updateTeamleaderDealPhase.isLoading || getTeamleaderDealsList.isLoading) && <SmallSpinner />}
        <Button
          variant="secondary"
          onClick={onHide}
        >
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TeamleaderDealPhasesListModal;

