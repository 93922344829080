import React from 'react';
import Root from './Root';
import { Route, Routes, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from './components/login/Login';

import Dashboard from './components/dashboard/Dashboard';
import LocationsList from './components/production/LocationsList';
import TaskTemplatesList from './components/production/TaskTemplatesList';
import ProductionTemplatesList from './components/production/ProductionTemplatesList';
import ProductionTemplate from './components/production/ProductionTemplate';
import ProjectsList from './components/projects/ProjectsList';
import Project from './components/projects/ProjectView';
import TeamleaderDealsList from './components/teamleader/TeamleaderDealsList';
import TeamleaderAuthorize from './components/teamleader/TeamleaderAuthorize';
import UsersList from './components/users/UsersList';
import RolesList from './components/users/RolesList';
import AuthenticatedRoute from './components/login/AuthenticatedRoute';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { LocalizationProvider } from './components/shared/ReactToolbox';

const WithParams = ({ component: Component, ...restProps }) => {
  const { id, ...restParams } = useParams();
  return <Component
    {...{
      ...id ? { id: parseInt(id) } : {},
      ...restParams,
      ...restProps,
    }}
  /> ;
}

export const routes = [
  {
    path: '',
    title: 'Projecten',
    element: null,
    children: [
      {
        index: true,
        element: <ProjectsList />,
      },
    ],
  },
  {
    path: 'teamleader',
    title: 'Offertes',
    children: [
      {
        index: true,
        element: <TeamleaderDealsList />,
      },
    ],
  },
  {
    path: 'users',
    title: 'Gebruikers',
    element: null,
    children: [
      {
        index: true,
        element: <UsersList />,
      },
      {
        path: 'roles',
        element: <RolesList />,
        title: 'Rollen',
      },
    ],
  },
  {
    path: 'production',
    title: 'Productie proces',
    element: null,
    children: [
      {
        index: true,
        element: <ProductionTemplatesList />,
      },
      {
        path: 'tasks',
        element: <TaskTemplatesList />,
        title: 'Taak templates',
      },
      {
        path: 'locations',
        element: <LocationsList />,
        title: 'Locaties',
      },
    ],
  },
];

const App = () => {
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider lang='nl'>
          <Root>
            <ToastContainer hideProgressBar={true} newestOnTop={true} />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                exact
                path="/"
                element={<AuthenticatedRoute component={Dashboard} redirectNonStaffUserTo={process.env.REACT_APP_WEBAPP_URL} />}
              >
                <Route
                  path=""
                  index={true}
                  element={<ProjectsList />}
                />
                <Route path="authorize-teamleader" element={<TeamleaderAuthorize />} />
                <Route
                  path='projects/:id'
                  element={<WithParams component={Project} />}
                />
                <Route
                  path="teamleader"
                >
                  <Route
                    path=""
                    index={true}
                    element={<TeamleaderDealsList />}
                  />
                </Route>
                <Route
                  path="users"
                >
                  <Route
                    path=""
                    index={true}
                    element={<UsersList />}
                  />
                  <Route
                    path="roles"
                    element={<RolesList />}
                  />
                </Route>
                <Route
                  path="production"
                >
                  <Route
                    path=""
                    index={true}
                    element={<ProductionTemplatesList />}
                  />
                  <Route
                    path='tasks'
                    element={<TaskTemplatesList />}
                  />
                  <Route
                    path="locations"
                    element={<LocationsList />}
                  />
                  <Route
                    path=':id'
                    element={<WithParams component={ProductionTemplate} />}
                  />
                </Route>
              </Route>
            </Routes>
            {/* {useRoutes([
              {
                path: "/login",
                element: <Login />
              },
              {
                path: "/teamleader",
                element: <TeamleaderAuthorize />
              },
              {
                exact: true,
                path: '/',
                element: <AuthenticatedRoute component={Dashboard} />,
                children: routes,
              },
            ])} */}
          </Root>
        </LocalizationProvider>
      </DndProvider>
    </div>
  );
}

export default App;
